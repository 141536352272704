
body {
  margin: 0; 
  border: none ;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -webkit-overflow-scrolling: touch;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  display: flex; 
  justify-content: center; 
  /* height: 80vh;  */

  height:100vh;
  width: 100vw;
  align-items: center;  
  text-align: center; 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

::selection {
  background-color: rgba(245, 123, 31, 1);
  color: black; 
 
}